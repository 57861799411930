import React,{useState,useEffect} from 'react'
import "./slider.css";
import './form.css';    
import { useNavigate } from 'react-router-dom';
import { apiRequestPOST } from '../utils/ApiCall';
import { BASE_URL } from '../utils/Constants';
import { BeatLoader } from 'react-spinners';
import { Alert } from 'bootstrap';


const Sliders = () => {
   
    const[index,setIndex] = useState(0);
    const [loading, setLoading] = useState(true);

     const navigate = useNavigate();


     const [bookData, setBookData] = useState([]);
     const onInputChange = (e, name) => {    
       const val = e; 
       let _bookData = {...bookData};
       _bookData[`${name}`] = val;
       setBookData(_bookData); 
     };
     const onInputChangeTwo = (e, name,e1, name1) => {  
      let _bookData = {...bookData};
      _bookData[`${name}`] = e;
      _bookData[`${name1}`] = e1;
      setBookData(_bookData); 
    }; 

    
    const onInputChangeThree = (e, name,e1, name1,e2, name2) => {  
      let _bookData = {...bookData};
      _bookData[`${name}`] = e;
      _bookData[`${name1}`] = e1;
      _bookData[`${name2}`] = e2;
      setBookData(_bookData); 
    };
    
    

    const onInputChangeChckbox_most = (e, name,e1, name1,e2, name2) => {  
     let _bookData = {...bookData};
     _bookData[`${name}`] = e;
     _bookData[`${name1}`] = e1;
     _bookData[`${'option_a'+e1}`] ='';
     _bookData[`${'option_b'+e1}`] ='';
     _bookData[`${'option_c'+e1}`] ='';
     _bookData[`${'option_d'+e1}`] ='';
     _bookData[`${name+e1}`] = e;
      

     _bookData[`${name2}`] = e2;
     setBookData(_bookData); 
   };
   const onInputChangeChckbox_least = (e, name,e1, name1,e2, name2) => {  
    let _bookData = {...bookData};
    _bookData[`${name}`] = e;
    _bookData[`${name1}`] = e1;
    _bookData[`${'zoption_a'+e1}`] ='';
    _bookData[`${'zoption_b'+e1}`] ='';
    _bookData[`${'zoption_c'+e1}`] ='';
    _bookData[`${'zoption_d'+e1}`] ='';
    _bookData[`${'z'+name+e1}`] = e;
    
    _bookData[`${name2}`] = e2;   
    setBookData(_bookData); 
  };
   

    const onCheckChange = (option,val,id,e, name,e1, name1) => {  
      let _bookData = {...bookData};
      _bookData[`${name}`] = e;
      _bookData[`${name1}`] = e1;
      setBookData(_bookData);  
    }; 
            
const clear_bookData = async() => {     
  setBookData([]); 
  setValidationSchema(false);  
}        
    const [validationSchema, setValidationSchema] = useState(false);
    const Validation_schema = () => {   
      bookData.option_most && 
      bookData.option_least  
        ?   buttonsubmit()
        : setValidationSchema(true) 
    };

    const buttonsubmit = () => {
      setValidationSchema(false);  
      let _bookData = {...bookData};
      _bookData['option_a'] = '';
      _bookData['option_a'] = '';
      _bookData['option_c'] = '';
      _bookData['option_d'] = '';
      _bookData['option_most'] = '';
      _bookData['option_least'] = '';
      setBookData(_bookData); 
      index==23?completeButton():setIndex(index + 1)
    };

    const completeButton = () => {  
      setLoading(true);
      setIndex(index + 1)
      localStorage.setItem('bookData',JSON.stringify(bookData))  
      
      apiRequestPOST(`${BASE_URL}app_ctrl/add_clients`, bookData, false)
      .then(async (response) => {   
        setLoading(false);
        console.log("setClientsList",response)  
        if (response.status == true) {  
          alert('Success');  
          navigate('/Last');
        }
      }, (error) => {
       alert('Failed,Try Again') ;
       navigate('/');
      });   
    };

     const [questionnaireList, setquestionnaireList] = useState([]);
     useEffect(() => {  
      const bookDataz= JSON.parse(localStorage.getItem('bookData'));
      if(bookDataz==''){
        navigate('/');
      }else{
      setBookData(bookDataz) 

      
  apiRequestPOST(`${BASE_URL}app_ctrl/questionnaire_list`, '', false)
  .then((response) => {
    setLoading(false);
   setquestionnaireList(response.data)
  }, (error) => {
     console.log(error);
  }); 
      }
    }, []);
 
  return (
        
    
    <div className='body'>
    
    <div className='form'>
       <h4 className='hea'>DISC ASSESSMENT</h4>
       <div className='content'>
        
       {loading ? (
        <div className="loader">
          <BeatLoader color="#000" loading={loading} />
        </div>
      ) : (
        <div className='item'>
          {questionnaireList.map((item,indexPeople) => {
            const {id , question,option_a,option_b,option_c,option_d} = item; 
            let position = "nextSlide";
            if(indexPeople === index) {
              position = "activeSlide"

            }
            
            if(indexPeople === index - 1 || (index === 0 && indexPeople === questionnaireList.length - 1) ) {
              position = "lastSlide";
            }
            return(
              <>
              {index+1==id&&
              < > 
            
              
                <table style={{width: '100%'}} className='item'>
      <thead>
        <tr><th colSpan={3}>{id+'.   '+question}</th></tr>
                    <tr>
                      <td></td>
                      <td id="mostleast">Most</td>
                      <td id="mostleast">Least</td> 
                    </tr>
                    <tr>
                      <td>{option_a}</td>
                      <td><input type="checkbox" id='checkbox' checked={bookData.option_a=='Most'&&bookData.option_most=='a'&&true}  onChange={(event) => onInputChangeChckbox_most('Most', 'option_a',id, 'val','a','option_most')}  /></td>
                      <td><input type="checkbox" id='checkbox' checked={bookData.option_a=='Least'&&bookData.option_least=='a'&&true} onChange={(event) => onInputChangeChckbox_least('Least', 'option_a',id, 'val','a','option_least')}  /></td> 
                    </tr>
                    <tr>
                      <td>{option_b}</td>
                      <td><input type="checkbox" id='checkbox'  checked={bookData.option_b=='Most'&&bookData.option_most=='b'&&true}   onChange={(event) => onInputChangeChckbox_most('Most', 'option_b',id, 'val','b','option_most')}  /></td>
                      <td><input type="checkbox" id='checkbox'  checked={bookData.option_b=='Least'&&bookData.option_least=='b'&&true}   onChange={(event) => onInputChangeChckbox_least('Least', 'option_b',id, 'val','b','option_least')}  /></td> 
                    </tr>
                    <tr>
                      <td>{option_c}</td>
                      <td><input type="checkbox" id='checkbox' checked={bookData.option_c=='Most'&&bookData.option_most=='c'&&true}   onChange={(event) => onInputChangeChckbox_most('Most', 'option_c',id, 'val','c','option_most')}  /></td>
                      <td><input type="checkbox" id='checkbox' checked={bookData.option_c=='Least'&&bookData.option_least=='c'&&true}   onChange={(event) => onInputChangeChckbox_least('Least', 'option_c',id, 'val','c','option_least')}  /></td> 
                    </tr>
                    <tr>
                      <td>{option_d}</td>
                      <td><input type="checkbox" id='checkbox' checked={bookData.option_d=='Most'&&bookData.option_most=='d'&&true}   onChange={(event) => onInputChangeChckbox_most('Most', 'option_d',id, 'val','d','option_most')}  /></td>
                      <td><input type="checkbox" id='checkbox' checked={bookData.option_d=='Least'&&bookData.option_least=='d'&&true}   onChange={(event) => onInputChangeChckbox_least('Least', 'option_d',id, 'val','d','option_least')}  /></td> 
                    </tr>

                    <tr>
                      <td colSpan={3}> <br/>
                      {validationSchema && !bookData.option_most && <span id="error" >*Most Required</span>} </td>
                      </tr> 
                      <tr>
                      <td colSpan={3}> 
                        {validationSchema && !bookData.option_least && <span  id="error" style={{color:'red'}}>*Least Required</span>} </td>
                    </tr>
                    </thead>
                  </table> <br></br>
                  
               
              
               
                <div>
             
               <div className='bu'>
               {/* <button className='prev' id='button1' onClick={() => setIndex(index - 1)}>
            <i className=''>Pre</i>
          </button >  */}
      {index!=24&&<button className='button1' onClick={Validation_schema}> Next</button>} 
               </div>
                </div> 
              </>
          }
          </>
            )
              
              
            

          })} 
         </div>
         )};

         </div>
        </div>
      </div>
    
  );
};

export default Sliders
