import axios from 'axios';
import get from 'lodash.get';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import messaging from '@react-native-firebase/messaging';
// import {
//   notifications,
//   messages,
//   NotificationMessage,
//   Android
// } from "react-native-firebase-push-notifications"

export const apiRequest = (url, payload, method = 'GET', debug = false) => {
  let debugMod = false;
  if (process.env.NODE_ENV === 'development' && debug) {
    debugMod = true;
  }
  const typeOfMethod = method === 'FILE_UPLOAD' ? 'GET' : method;

  return new Promise(async (resolve, reject) => {
    // let progress = 0;
    // axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    // axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';

    // const token =reduxState.configReducer.showToken
    // console.log("token",token)
    // const appToken =   await messaging().getToken();
    const appToken = null;
    const token = await AsyncStorage.getItem('userToken');
    console.log('userToken_key', appToken);
    let headerProps = {};
    if (appToken != null) {
      if (token != null) {
        headerProps = {
          Accept: '"application/json, text/plain, */*"',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${appToken} ${token}`,
        };
      } else {
        headerProps = {
          Accept: '"application/json, text/plain, */*"',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${appToken}`,
        };
      }
    } else {
      headerProps = {
        Accept: '"application/json, text/plain, */*"',
        'Content-Type': 'application/x-www-form-urlencoded',
      };
    }

    axios({
      method: typeOfMethod,
      url,
      data: method === 'FILE_UPLOAD' ? payload : JSON.stringify(payload),
      headers: headerProps,
    })
      .then(res => {
        if (debugMod) {
          console.group(
            `Network request ${url} request status code ${res.status}`,
          );
          console.log(res);
          console.groupEnd();
        }
        if (res.status !== 200) {
          reject(get(res, 'response.data.message', 'Something went wrong'));
        }
        resolve(res.data);
      })
      .catch(err => {
        if (debugMod) {
          console.group(`Error ${url}`);
          console.log(err);
          console.groupEnd();
        }
        reject(get(err, 'response.data.message', 'Something went wrong'));
      });
  });
};

export const apiRequestPOST = (url, payload, debug = false) => {
  let debugMod = false;
  if (process.env.NODE_ENV === 'development' && debug) {
    debugMod = true;
  }
  // console.log('>> >',payload)

  return new Promise(async (resolve, reject) => {
    // const appToken =    await messaging().getToken();
    const appToken = await AsyncStorage.getItem('userToken');
    const token = await AsyncStorage.getItem('userToken');
    // console.log('userToken_key',appToken)
    let headerProps = {};
    if (appToken != null) {
      if (token != null) {
        headerProps = {
          Accept: '"application/json, text/plain, */*"',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${appToken} ${token}`,
        };
      } else {
        headerProps = {
          Accept: '"application/json, text/plain, */*"',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${appToken}`,
        };
      }
    } else {
      headerProps = {
        Accept: '"application/json, text/plain, */*"',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer 1`,
      };
    }

    const keys = Object.keys(payload);

    var formDate = [];

    keys.forEach((key, index) => {
      formDate.push(key + '=' + payload[key]);
    });
    formDate = formDate.join('&');

    axios({
      method: 'POST',
      url,
      data: payload,
      headers: headerProps,
    })
      .then(res => {
        if (debugMod) {
          console.group(
            `Network request ${url} request status code ${res.status}`,
          );
          console.log(res);
          console.groupEnd();
        }
        if (res.status !== 200) { 
          reject(get(res, 'response.data.message', 'Something went wrong'));
        }
        resolve(res.data);
      })
      .catch(err => {
        if (debugMod) {
          console.group(`Error ${url}`);
          // console.log(err);
          console.groupEnd();
        }
        reject(get(err, 'response.data.message', err));
      });
  });
};

export const apiRequestGET = (url, payload, debug = false) => {
  let debugMod = false;
  if (process.env.NODE_ENV === 'development' && debug) {
    debugMod = true;
  }
  // console.log('>> >',payload)

  return new Promise(async (resolve, reject) => {
    // const appToken =    await messaging().getToken();
    const appToken = await AsyncStorage.getItem('userToken');
    const token = await AsyncStorage.getItem('userToken');
    // console.log('userToken_key',appToken)
    let headerProps = {};
    if (appToken != null) {
      if (token != null) {
        headerProps = {
          Accept: '"application/json, text/plain, */*"',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${appToken} ${token}`,
        };
      } else {
        headerProps = {
          Accept: '"application/json, text/plain, */*"',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${appToken}`,
        };
      }
    } else {
      headerProps = {
        Accept: '"application/json, text/plain, */*"',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer 1`,
      };
    }

    const keys = Object.keys(payload);

    var formDate = [];

    keys.forEach((key, index) => {
      formDate.push(key + '=' + payload[key]);
    });
    formDate = formDate.join('&');

    axios({
      method: 'GET',
      url,
      data: payload,
      headers: headerProps,
    })
      .then(res => {
        if (debugMod) {
          console.group(
            `Network request ${url} request status code ${res.status}`,
          );
          console.log(res);
          console.groupEnd();
        }
        if (res.status !== 200) {
          reject(get(res, 'response.data.message', 'Something went wrong'));
        }
        resolve(res.data);
      })
      .catch(err => {
        if (debugMod) {
          console.group(`Error ${url}`);
          console.log(err);
          console.groupEnd();
        }
        reject(get(err, 'response.data.message', err));
      });
  });
};
