import logo from './logo.svg';
import './App.css';

import { BrowserRouter,Routes,Route } from 'react-router-dom'
import Sliders from './component/Sliders';
import Form  from './component/Form';
import Last from './component/Last';
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from './component/Main'

function App() {
  return (
    
<BrowserRouter>
    
    <Routes>
      <Route path=''  inex element={<Form/>}/>
      <Route path='/Sliders'  inex element={<Sliders/>}/>
      <Route path='/Last'  inex element={<Last/>}/>
      {/* <Route path='*' element={<NotFound/>}/> */}
    </Routes>

    </BrowserRouter>
  );
}

export default App;
