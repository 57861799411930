import React, { useState, useContext, useEffect } from 'react'
import { Link,useNavigate } from 'react-router-dom' 
import './form.css';   

const Form = () => {
  
const [bookData, setBookData] = useState([]);
const onInputChange = (e, name) => {  
  const val = e; 
  let _bookData = {...bookData};
  _bookData[`${name}`] = val;
  setBookData(_bookData); 
};

const navigate = useNavigate();
 

const clear_bookData = async() => {     
  setBookData([]); 
  setValidationSchema(false);  
} 
const [validationSchema, setValidationSchema] = useState(false);
const Validation_schema = () => {  
  bookData.name && 
  bookData.phone  
    ?  buttonsubmit() 
    : setValidationSchema(true) 
};


const buttonsubmit = () => {
  localStorage.setItem('bookData',JSON.stringify(bookData))   
  navigate('/Sliders');
};

  return (
    <div className='body'>
    
        <div className='form'>
           <h4 className='hea'>DISC ASSESSMENT</h4>
            
        <div className='content'>
         
        <div className='item'>
          
        <table style={{width: '100%'}}>
      <thead>
        <tr>
          <th> 
                <div className='item'> <label class="text1"  for="uname"> <h6>Name: </h6></label><br></br>
                        <input className='input1'   type="text"  placeholder="Enter Your Name"  onKeyUp={(event) => onInputChange(event.target.value, 'name')} ></input><br></br>
                        {validationSchema && !bookData.name && <span class="text1" style={{color:'red'}}>Required</span>} </div>
           </th>
        </tr> 
        
         <tr>
          <th>
                         
                        <div className='item'><label class="text1"  for="uname"> <h6>Phone Number: </h6></label><br></br>
                        <input className='input1'   type="number"  placeholder="Enter Your Phone Number"  onKeyUp={(event) => onInputChange(event.target.value, 'phone')} ></input><br></br>
                        {validationSchema && !bookData.phone && <span class="text1" style={{color:'red'}}>Required</span>} </div>
                        </th>
        </tr> 

        <tr>
          <th>
                        <div className='item'><label class="text1"  for="uname"> <h6>Email:</h6></label><br></br>
                        <input className='input1'   type="email"  placeholder="Enter Your Email"  onKeyUp={(event) => onInputChange(event.target.value, 'email')} ></input><br></br></div>
                        

            </th>
        </tr>  
        
          <tr>
          <th> 
                        <div className='bu'> 
                        <button className='button1' onClick={Validation_schema}> Next</button>  
                        </div>

                        </th>
        </tr>  
                        </thead> 
    </table>
                        </div>
        </div>
      
                       </div>
                       </div>
        
    
  );
};


export default Form
